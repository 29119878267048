<template>
  <div class="work_learner_data">

    <div class="tips">
      <i class="el-icon-warning" style="color: #07D14A;margin-right:10px"></i>
      统计数据只包含已提交人数，未提交不纳入统计。
    </div>
    <div class="form-container">
      <!-- <div class="form-filter flex-align">
        <div class="fileter-item">
          <p>{{ work.answerMembers }}<i>人</i></p>
          <p>已提交</p>
        </div>
        <div class="fileter-item">
          <p>{{ work.totalMembers - work.answerMembers }}<i>人</i></p>
          <p>未提交</p>
        </div>
        <div class="fileter-item">
          <p>
            {{ (work.answerMembers / work.totalMembers).toFixed(2)*100 }}<i>%</i>
          </p>
          <p>提交比例</p>
        </div>
      </div> -->

      <div class="form-inline flex-between" style="">
        <div class="data-count" id="data-count"></div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <!-- <div class="formInline-left">
            <el-form-item>
              <el-select v-model="formInline.region" placeholder="提交状态">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
                <el-option label="区域三" value="beijing"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="formInline.region"
                placeholder="地区"
                suffix-icon="el-icon-time"
              >
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select v-model="formInline.region" placeholder="学校">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
                <el-option label="区域三" value="beijing"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select v-model="formInline.region" placeholder="年级">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
                <el-option label="区域三" value="beijing"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label>
              <el-input
                size="medium"
                placeholder="请输入学员姓名"
                suffix-icon="el-icon-search"
              ></el-input>
            </el-form-item>
          </div> -->
          <div class="formInline-right flex-align-right csp">
            <!-- <div class="form-inline-item">
              <i class="el-icon-setting"></i>
              <span>等级设置</span>
            </div> -->
            <el-select v-model="statusValue" placeholder="请选择提交状态" size="small" @change="changeStatus">
              <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <div class="form-inline-item flex-align" @click="downloadwork">
              <i class="el-icon-folder-opened"></i>
              <span>导出数据</span>
            </div>
            <!-- <div class="form-inline-item flex-align" @click="makepack" v-loading.fullscreen.lock="fullscreenLoading"
              element-loading-text="打包中">
              <i class="el-icon-folder-opened"></i>
              <span>批量打包作业</span>
            </div> -->
            <div class="form-inline-item flex-align" @click="showdialog">
              <img src="@/assets/download.png" style="width:20px;height:17px;margin-right:8px" />
              <span>下载打包作业</span>
            </div>
          </div>
        </el-form>
      </div>
      <div class="table_section">
        <el-table :data="tableData" border style="width: 100%" stripe>
          <!-- <el-table-column label="排名" width="50" align="center" type="index" >

            </el-table-column> -->
          <el-table-column label="排名" width="100" align="center">
            <template slot-scope="scope">
              {{
                scope.row.submitStatus == "已提交" ? scope.row.indexnum : "-"
              }}
            </template>
          </el-table-column>
          <el-table-column prop="userName" label="姓名" width="100" align="center">
          </el-table-column>
          <el-table-column prop="phone" label="手机号" width="150" align="center">
          </el-table-column>
          <el-table-column prop="courseName" label="地区" width="120" align="center">
          </el-table-column>
          <el-table-column prop="schoolName" label="学校" width="150" align="center">
          </el-table-column>
          <el-table-column prop="gradeName" label="年级" width="160" align="center">
            <template slot-scope="scope">
              {{ regExp('grade', scope.row.gradeName) }}
              <!-- {{scope.row.gradeName? '年级' : '--'}} -->
            </template>
          </el-table-column>
          <el-table-column prop="className" label="班级" width="100" align="center">
            <template slot-scope="scope">
              {{ regExp('class', scope.row.className) }}
              <!-- {{scope.row.className? '' : '--'}} -->
            </template>
          </el-table-column>
          <el-table-column prop="role" label="角色" width="150" align="center">
          </el-table-column>
          <el-table-column prop="submitStatus" label="提交状态" width="150" align="center">
          </el-table-column>
          <el-table-column prop="createTime" label="提交时间" width="150" align="center">
          </el-table-column>
          <!--<el-table-column
              prop="courseLabel"
              label="是否被点评"
              width="150"
              align="center"
            >
            </el-table-column>
             <el-table-column
              prop="courseLabel"
              label="被点赞数"
              width="150"
              align="center"
            >
            </el-table-column>
             <el-table-column
              prop="courseLabel"
              label="是否被精选"
              width="150"
              align="center"
            >
            </el-table-column>
             <el-table-column
              prop="courseLabel"
              label="资源数量"
              width="150"
              align="center"
            > 
            </el-table-column>-->
          <el-table-column prop="totalPoints" label="得分" width="150" align="center">
          </el-table-column>
          <!-- <el-table-column
              prop="courseLabel"
              label="等级"
              width="150"
              align="center"
            >
            </el-table-column>
             <el-table-column
              prop="courseLabel"
              label="xx一级能力"
              width="150"
              align="center"
            >
            </el-table-column>
             <el-table-column
              prop="courseLabel"
              label="xx一级能力"
              width="150"
              align="center"
            > -->
          <!-- </el-table-column>
             <el-table-column
              prop="courseLabel"
              label="xx一级能力"
              width="150"
              align="center"
            >
            </el-table-column>
             <el-table-column
              prop="courseLabel"
              label="xx一级能力"
              width="150"
              align="center"
            >
            </el-table-column> -->
          <!-- <el-table-column fixed="right" label="操作" width="200" align="center">
              <template slot-scope="scope">
                <el-button @click="handleClick(scope.row)" type="text" size="small"
                  >查看</el-button
                >
                <el-button type="text" size="small">删除</el-button>
              </template>
            </el-table-column> -->
        </el-table>
        <div class="block page_size">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page.sync="currentPage" :page-size="pageSize" layout="prev, pager, next,sizes" :total="totalSize"
            :page-sizes="[10, 20, 50]"></el-pagination>
        </div>
      </div>
      <!-- 下载作业弹出框 -->
      <el-dialog title="打包作业列表" :visible.sync="dialogTableVisible">
        <el-table :data="packagelist">
          <el-table-column property="filename" label="作业名称"></el-table-column>
          <el-table-column property="time" label="打包时间" width="150"></el-table-column>

          <!-- <el-table-column :property="identifier?'已完成':'打包中'" label="打包状态" width="150"></el-table-column> -->
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <el-button size="mini" type="text" v-if="scope.row.identifier"
                @click="handleEdit(scope.$index, scope.row)">下载</el-button>
              <el-button size="mini" type="text" disabled v-else @click="handleEdit(scope.$index, scope.row)">下载
              </el-button>
              <!-- <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props: {
    work: {
      type: Object,
    },
  },
  data() {
    return {
      gridData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄',
        status: "已完成"
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄',
        status: "已完成"
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄',
        status: "已完成"
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄',
        status: "已完成"
      }],
      tableData: [{
        number: 1,
        courseCoding: "322131",
        courseName: "课程理解",
        courseType: "阅读类",
        teacher: "孙宏慧 135****5677",
        courseLabel: "最热",
      },
      {
        number: 1,
        courseCoding: "322131",
        courseName: "男",
        courseType: "135****5858",
        teacher: 2341563,
        courseLabel: "上海",
      },
      {
        number: 1,
        courseCoding: "322131",
        courseName: "男",
        courseType: "135****5858",
        teacher: 2341563,
        courseLabel: "上海",
      },
      {
        number: 1,
        courseCoding: "322131",
        courseName: "男",
        courseType: "135****5858",
        teacher: 2341563,
        courseLabel: "上海",
      },
      {
        number: 1,
        courseCoding: "322131",
        courseName: "男",
        courseType: "135****5858",
        teacher: 2341563,
        courseLabel: "上海",
      },
      ],
      formInline: {
        user: "",
        region: "",
      },
      value1: "",
      currentPage: 1,
      pageSize: 10,
      totalSize: 0,
      dialogTableVisible: false,
      fullscreenLoading: false,
      packagelist: [],
      statusList: [
        { label: '全部', value: '' },
        { label: '未提交', value: 0 },
        { label: '已提交', value: 1 },
      ],
      statusValue: ''
    };
  },
  methods: {
    initEchart() {
      const that = this;
      let dom = document.getElementById("data-count");
      // console.log(dom)
      this.myChart = this.$echarts.init(dom);
      window.addEventListener('resize', () => {
        this.myChart.resize()
      })
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            console.log('params', params);
            let str = ` ${params.marker}${params.name.split(' ')[0]}：${params.value}`
            return str;
          }
        },
        legend: {
          right: '0%',
          top: 'center',
          orient: "vertical",
          icon: 'circle',
          textStyle: {
            fontSize: 12
          },
        },
        series: [{
          name: '访问来源',
          type: 'pie',
          left: '-40%',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          label: {
            position: 'center',
            formatter: `提交比例${((this.work.answerMembers / this.work.totalMembers) * 100).toFixed(1)}%`,
          },
          labelLine: {
            show: false
          },
          data: [{
            value: this.work.answerMembers,
            name: `已提交 ${this.work.answerMembers}`,
            itemStyle: {
              color: '#FFB822'
            },
          },
          {
            value: this.work.totalMembers - this.work.answerMembers,
            name: `未提交 ${this.work.totalMembers - this.work.answerMembers}`,
            itemStyle: {
              color: '#508EF9'
            },
          },
          ]
        }]
      };
      this.myChart.setOption(option);
    },
    showdialog() {
      // this.dialogTableVisible = true;
      // this.downloadtask()
      window.open(`${axios.defaults.baseURL}/quest/front/answer/exportZip/export?questId=${this.work.id}`)
    },
    handleEdit(index, row) {
      console.log(index, row)
      window.location.href = this.downloadURL + row.identifier
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.currentPage = 1;
      this.getWorkData();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getWorkData();
    },
    format(percentage) {
      let arr = 10;
      percentage = percentage + "/" + arr;
      return `${percentage}`;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    async getWorkData(status='') {
      let params = {
        questId: this.work.id,
        page: this.currentPage,
        limit: this.pageSize,
        status
      };
      let resData = await this.$Api.Form.getWorkData(params);
      console.log(resData);
      this.tableData = resData.data.list;
      this.totalSize = resData.data.totalCount;
      this.tableData.forEach((e, index) => {
        e.indexnum = index + 1;
        e.submitStatus = e.createTime ? "已提交" : "未提交";
        e.totalPoints = e.totalPoints ? e.totalPoints.toFixed(1) : '0.0';
        e.createTime = e.createTime ? e.createTime : '--';
        e.role = e.role == '老师' ? '参训教师' : e.role
      });
    },
    downloadwork() {
      window.location.href = axios.defaults.baseURL + "/quest/front/answer/export?questId=" + this.work.id
    },
    makepack() {
      this.fullscreenLoading = true;
      let query = {
        questId: this.work.id
      }
      this.$Api.Form.makepack(query)
        .then(res => {
          console.log(res)
          this.fullscreenLoading = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    downloadtask() {
      // window.location.href = axios.defaults.baseURL + "/quest/front/answer/exportZip/export?questId=" + this.work.id
      let query = {
        questId: this.work.id
      }
      this.$Api.Form.downloadpack(query)
        .then(res => {
          console.log("打包列表--", res)
          this.packagelist = res.data
        })
        .catch(err => {
          console.log(erss)
        })

    },
    regExp(type, str) {
      if (type == 'grade') {
        let reg = RegExp(/年级/);
        return str ? (reg.test(str) ? str : str + '年级') : '--'
      } else {
        let reg = RegExp(/班/);
        return str ? (reg.test(str) ? str : str + '班') : '--'
      }
    },
    async changeStatus(status){
      this.getWorkData(status)
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        // console.log(this.work);
        this.getWorkData();
        this.initEchart();
      }, 100);
    });
    console.log(this.regExp('class', "3班"))
  },
  activated() {
    console.log("activted");
    this.$nextTick(() => {
      setTimeout(() => {
        console.log(this.work);
        this.getWorkData();
      }, 100);
    });
  },
};
</script>

<style lang="less" scoped>
.work_learner_data {
  box-sizing: border-box;
  margin-bottom: 50px;
  padding: 35px 46px 50px;

  .data-count {
    width: 270px;
    height: 200px;
  }

  .tips {
    background: rgba(7, 209, 74, 0.07);
    border-radius: 3px;
    line-height: 36px;
    padding-left: 13px;
    color: #666
  }

  .form-inline {
    padding: 0 0 0 40px;
    align-items: flex-end;
  }

  .form-filter {
    .fileter-item {
      margin-right: 20px;
      margin-bottom: 35px;
      color: #343434;
      text-align: center;

      p:first-child {
        font-size: 24px;
        font-weight: bold;
      }

      p:last-child {
        font-size: 18px;
      }
    }
  }

  .el-form-item__label {
    font-size: 16px;
    color: #595959;
  }

  // .el-form-item {
  //   margin-right: 20px;

  //   .el-input__inner {
  //     border: 1px solid #858585;
  //     border-radius: 2px;
  //     color: #343434;
  //   }

  //   .el-input .el-select__caret {
  //     color: #343434;
  //   }

  //   .el-input__inner::-webkit-input-placeholder {
  //     /* WebKit browsers */
  //     color: #343434;
  //   }

  //   .el-input__inner::-moz-placeholder {
  //     /* Mozilla Firefox 19+ */
  //     color: #343434;
  //   }

  //   .el-input__inner:-ms-input-placeholder {
  //     /* Internet Explorer 10+ */
  //     color: #343434;
  //   }
  // }

  // .el-form-item__content {
  //   .el-select {
  //     .el-input--suffix {
  //       max-width: 116px;

  //       .el-input__inner {
  //         height: 30px;
  //         line-height: 30px;
  //       }
  //     }
  //   }

  //   .el-input {
  //     width: 260px;

  //     .el-input__inner {
  //       height: 30px;
  //       line-height: 30px;
  //     }
  //   }
  // }

  .formInline-right {
    margin-bottom: 20px;

    /deep/ .el-select .el-input--suffix {
      // width: 100px;
      max-width: none;
    }
    /deep/ .el-input__inner{
      height: 30px;
      line-height: 30px;
      color: #333;
    }

    .form-inline-item {
      margin-left: 22px;

      &:last-child {
        margin-right: 0;
      }

      i {
        vertical-align: sub;
        margin-right: 8px;
        font-size: 20px;
        // font-weight: bold;
        color: #508EF9;
      }

      span {
        font-size: 14px;

        color: #5a5a5a;
      }
    }
  }


}

.el-pager {
  .number {
    height: 32px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    margin: 0 5px;
  }

  li.active {
    background: #508EF9;
    color: #fff;
  }

  & li.active+li {
    border-left: 1px solid #dfdfdf;
  }
}

.page_size {
  text-align: right;
  padding: 20px 0;
}

.table-section {
  margin-top: 30px;
}


.nodata {
  color: #eee;
}

/deep/.el-table--border td {
  border-right: 0;
}

/deep/ .el-table__body-wrapper {
  /*修改滚动条样式*/
  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(191, 191, 191, .5);
    border-radius: 15px;
  }
}

/deep/ .el-table {
  border-radius: 8px;
  border: 1px solid rgba(199, 199, 199, .43);
}

/deep/ .el-table__header {
  thead th {
    color: #000;
    font-weight: 400;
    background-color: RGBA(250, 251, 255, 1);
    border: 0;
  }
}

/deep/ .el-table--striped .el-table__body tr.el-table__row--striped td {
  background-color: rgba(243, 243, 244, 1);
}
</style>